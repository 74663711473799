@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
}

#about_content::-webkit-scrollbar,
#project_content::-webkit-scrollbar {
  display: none;
}

#about_content,
#project_content {
  scrollbar-width: none;
}

/*======== CUSTOM UTILITY CLASSES ========*/

/* Sets the object height to 100% of the visible screenspace */
/* This is useful on mobile where the search bar in the browser is in the way */
.adjusted_max_h {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.opacity_fade_in {
  animation: 1s ease-in 0s 1 opacityAnimation;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
